import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/img/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.marginRight = '15px';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.marginRight = '0';
      document.body.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    return () => {
      document.body.style.marginRight = '0';
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`navbar navbar-expand-lg fixed-top ${scrolled ? 'navbar-scrolled' : ''}`}>
        <div className="container">
          <div className="navbar-translate">
            <Link className="navbar-brand" to="/" rel="tooltip" title="AI Tech Consulting" data-placement="bottom">
              <div className="brand-container">
                <img style={{ width: "60px"}} src={logo} alt="hai-logo"/>
                <span>Human-Centered AI</span>
              </div>
            </Link>
            <button 
              className="navbar-toggler" 
              type="button" 
              onClick={toggleNavbar}
              aria-expanded={isOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ''}`} id="navigation">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#services" onClick={toggleNavbar}>Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tools" onClick={toggleNavbar}>Tools</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#blog" onClick={toggleNavbar}>Blog</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about" onClick={toggleNavbar}>About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact" onClick={toggleNavbar}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {isOpen && (
        <div 
          className="navbar-overlay"
          onClick={toggleNavbar}
          aria-label="Close menu"
        />
      )}
    </>
  );
};

export default Navbar;