import OpenAI from "openai";

if (!process.env.REACT_APP_OPENAI_API_KEY) {
  console.error('OpenAI API key is missing! Make sure REACT_APP_OPENAI_API_KEY is set in your .env file');
}

if (!process.env.REACT_APP_ASSISTANT_ID) {
  console.error('Assistant ID is missing! Make sure REACT_APP_ASSISTANT_ID is set in your .env file');
}

// Initialize the OpenAI client with your API key
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

// Add a helper function to validate required parameters
const validateParams = (params) => {
  const missing = [];
  Object.entries(params).forEach(([key, value]) => {
    if (!value) missing.push(key);
  });
  
  if (missing.length > 0) {
    throw new Error(`Missing required parameters: ${missing.join(', ')}`);
  }
};

// Add text cleanup function
const cleanupText = (text) => {
  return text
    // Remove citation markers like 【7:2†source】
    .replace(/【[^】]*】/g, '')
    // Remove other citation formats like [1], [2:3], etc.
    .replace(/\[\d+(?::\d+)?\]/g, '')
    // Remove special characters that might appear in the text
    .replace(/[†‡§]/g, '')
    // Remove extra whitespace
    .replace(/\s+/g, ' ')
    // Remove trailing/leading whitespace
    .trim();
};

export { openai, validateParams, cleanupText };