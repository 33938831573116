import React, { useState, useEffect } from 'react';
import './ClientWorkSection.css';
import DasksScreenshot from '../assets/img/DasksScreenshot.PNG'; // Import the image

// Import or define client work data
// For now we'll start with one example
const clientProjects = [
  {
    id: 1,
    name: "Dasks Greek Grill",
    description: "The Best Gyro in Salt Lake City just got a new website",
    image: DasksScreenshot, // Use the imported image
    url: "https://www.dasks.com",
    tags: ["React", "Restaurant", "Web Design"]
  },
  // More projects can be added here in the same format
];

const ClientWorkSection = () => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('client-work');
      if (section) {
        const rect = section.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on mount
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleProjectClick = (url) => {
    // Ensure URL has proper protocol
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <section id="client-work" className="client-work-section">
      <div className="container">
        <div className="section-header">
          <h2 className={`section-title ${isVisible ? 'animate-in' : ''}`}>
            <span className="highlight">Our Client Work</span>
          </h2>
        </div>
        
        <div className="projects-grid-container">
          <div className="projects-grid centered-grid">
            {/* Main project card */}
            <div 
              key={clientProjects[0].id} 
              className={`project-card ${hoveredProject === clientProjects[0].id ? 'hovered' : ''} ${isVisible ? 'animate-in' : ''}`}
              style={{ animationDelay: '0.2s' }}
              onMouseEnter={() => setHoveredProject(clientProjects[0].id)} 
              onMouseLeave={() => setHoveredProject(null)}
              onClick={() => handleProjectClick(clientProjects[0].url)}
            >
              <div className="project-image-container">
                <img src={clientProjects[0].image} alt={clientProjects[0].name} className="project-image" />
                <div className="project-overlay">
                  <span className="view-site">Visit Site</span>
                </div>
              </div>
              <div className="project-info">
                <h3 className="project-title">{clientProjects[0].name}</h3>
                <p className="project-description">{clientProjects[0].description}</p>
                <div className="project-tags">
                  {clientProjects[0].tags.map((tag, i) => (
                    <span 
                      key={i} 
                      className="project-tag"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            
            {/* House building placeholder card */}
            <div className="project-card coming-soon house-card" style={{ animationDelay: '0.3s' }}>
              <div className="placeholder-content">
                <div className="house-container">
                  <svg viewBox="0 0 200 150" className="house-svg">
                    {/* Ground/Foundation */}
                    <rect className="ground" x="40" y="120" width="120" height="10" fill="#8B5A2B" />
                    
                    {/* House Structure */}
                    <rect className="walls" x="60" y="70" width="80" height="50" fill="#D3D3D3" />
                    
                    {/* Roof */}
                    <polygon className="roof" points="50,70 100,30 150,70" fill="#8B0000" />
                    
                    {/* Door */}
                    <rect className="door" x="90" y="95" width="20" height="25" fill="#8B4513" />
                    <circle className="doorknob" cx="105" cy="110" r="2" fill="#FFD700" />
                    
                    {/* Windows */}
                    <rect className="window-left" x="70" y="80" width="15" height="15" fill="#87CEEB" />
                    <rect className="window-right" x="115" y="80" width="15" height="15" fill="#87CEEB" />
                    
                    {/* Window Frames */}
                    <line className="window-frame" x1="77.5" y1="80" x2="77.5" y2="95" stroke="#5F5F5F" strokeWidth="1" />
                    <line className="window-frame" x1="70" y1="87.5" x2="85" y2="87.5" stroke="#5F5F5F" strokeWidth="1" />
                    <line className="window-frame" x1="122.5" y1="80" x2="122.5" y2="95" stroke="#5F5F5F" strokeWidth="1" />
                    <line className="window-frame" x1="115" y1="87.5" x2="130" y2="87.5" stroke="#5F5F5F" strokeWidth="1" />
                    
                    {/* Chimney */}
                    <rect className="chimney" x="120" y="40" width="10" height="20" fill="#8B4513" />
                    <rect className="smoke1" x="118" y="30" width="14" height="5" rx="2.5" fill="#D3D3D3" opacity="0" />
                    <rect className="smoke2" x="115" y="20" width="16" height="6" rx="3" fill="#D3D3D3" opacity="0" />
                    <rect className="smoke3" x="112" y="10" width="18" height="7" rx="3.5" fill="#D3D3D3" opacity="0" />
                  </svg>
                </div>
                <h4>More in the works</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientWorkSection; 